import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './services/authguard/authguard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthguardService],
    // Routes below are accessible only if user is logged
    children: [
      {
        path: '',
        loadChildren: () => import('./logged-area/logged-area.module').then( m => m.LoggedAreaPageModule)
      },
      {
        path: 'logged-area',
        loadChildren: () => import('./logged-area/logged-area.module').then( m => m.LoggedAreaPageModule)
      },
      {
        path: 'alerts',
        loadChildren: () => import('./alerts/alerts.module').then( m => m.AlertsPageModule)
      },
      {
        path: 'objective-calculator',
        loadChildren: () => import('./objective-calculator/objective-calculator.module').then( m => m.ObjectiveCalculatorPageModule)
      },
      {
        path: 'score-calculator',
        loadChildren: () => import('./score-calculator/score-calculator.module').then( m => m.ScoreCalculatorPageModule)
      },
      {
        path: 'learn',
        loadChildren: () => import('./learn/learn.module').then( m => m.LearnPageModule)
      },
      {
        path: 'challenges',
        loadChildren: () => import('./challenges/challenges.module').then( m => m.ChallengesPageModule)
      }
    ]
  },  
  // This route is public, anyone can access
  {
    path: 'quiz',
    loadChildren: () => import('./quiz/quiz.module').then( m => m.QuizPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./new-sign-in/new-sign-in.module').then( m => m.NewSignInPageModule)
  },
  {
    path: 'thanks',
    loadChildren: () => import('./thanks/thanks.module').then( m => m.ThanksPageModule)
  },
  {
    path: 'text-quiz',
    loadChildren: () => import('./text-quiz/text-quiz.module').then( m => m.TextQuizPageModule)
  },
  {
    path: 'score',
    loadChildren: () => import('./score/score.module').then( m => m.ScorePageModule)
  },
  {
    path: 'nord-quiz-main',
    loadChildren: () => import('./nord-quiz-main/nord-quiz-main.module').then( m => m.NordQuizMainPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
